import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = ()=>import("../views/home/Home.vue")
const NewsList = ()=>import("../views/list/NewsList.vue")
const NewsPage = ()=>import("../views/list/NewsPage.vue")
const NewsDetails = ()=>import("../views/details/NewsDetails.vue")
const ProductList = ()=>import("../views/product/ProductList.vue")
const ProductDetails = ()=>import("../views/product/ProductDetails.vue")
const ExpertList = ()=>import("../views/expert/ExpertList.vue")
const ExpertDetails = ()=>import("../views/expert/ExpertDetails.vue")
const SearchList = ()=>import("../views/list/SearchList.vue")
const Certificate = ()=>import("../views/certificate/Certificate.vue")
const Coiccert = ()=>import("../views/coiccert/Coiccert.vue")




const routes = [
  {
    path: "",
    redirect: "/home"
  },
  {
    path: "/home",
    component: Home
  },
  {
    path: "/newslist",
    component: NewsList
  },
  {
    path: "/newspage",
    component: NewsPage
  },
  {
    path: "/newsdetails",
    component: NewsDetails
  },
  {
    path: "/productlist",
    component: ProductList
  },
  {
    path: "/productdetails",
    component: ProductDetails
  },
  {
    path: "/expertlist",
    component: ExpertList
  },
  {
    path: "/expertdetails",
    component: ExpertDetails
  },
  {
    path: "/searchlist",
    component: SearchList
  },
  {
    path: "/certificate",
    component: Certificate
  },
  {
    path: "/coiccert",
    component: Coiccert
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
