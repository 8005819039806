import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取首页所有信息 /ewi/guest/Home/GetHomeAllInfo
export function getHomeAllInfo(){
  return request({
    url: '/ewi/guest/Home/GetHomeAllInfo',
    method: 'get',
    params: {
      systemNumber: systemNumber
    }
  })
}

// 获取验证码 /ewi/guest/Home/GetCaptcha
export function getetCaptcha(){
  return request({
    url: '/ewi/guest/Home/GetCaptcha',
    method: 'get'
  })
}

// 获取网站设置 /ewi/guest/Home/GetCacheSetWeb
export function getCacheSetWeb(){
  return request({
    url: '/ewi/guest/Home/GetCacheSetWeb',
    method: 'get',
    params: {
      systemNumber: systemNumber
    }
  })
}
