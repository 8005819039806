import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入elementui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 引入swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'//不同版本样式引入方位置不一样，我这个版本就用这种
Vue.use(VueAwesomeSwiper, /* { default global options } */)

// 浏览器标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
