<template>
	<div class="container">
    <div class="footer-top">
      <div class="top-center">
        <!-- <div class="item">
          <router-link target="_blank" to="/newspage?menuId=23&menuName=联系我们">联系我们</router-link>
        </div>
        <div class="item" @click="showAlert">
          <router-link to="">网站留言</router-link>
        </div> -->
        <!-- <div class="item">
          <router-link to="">版权声明</router-link>
        </div>
        <div class="item">
          <router-link to="">网站承诺</router-link>
        </div> -->
      </div>
    </div>
    <div class="footer-bottom">
      <div class="bottom-center">
        <p><span v-if="webSet.copyright">{{ webSet.copyright }}</span>    <span v-if="webSet.techSupport">{{ webSet.techSupport }}  技术支持</span></p>
        <p><span v-if="webSet.phone">电话：{{ webSet.phone}}</span>    <span v-if="webSet.email">电子邮箱：{{ webSet.email }}</span>   <span v-if="webSet.address">办公地址：{{ webSet.address }}</span> <span v-if="webSet.fax">传真：{{ webSet.fax }}</span></p>
        <p v-if="webSet.icp">
          <a target="_blank" href="https://beian.miit.gov.cn">ICP备案 : {{ webSet.icp }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCacheSetWeb } from '../../network/home'

export default {
  name: "Footer",
  data () {
    return {
      webSet: {}
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getCacheSetWeb()
  },
  watch: {
    
  },
  methods: {
    showAlert() {
      alert('该功能已关闭！');
    },
    // 网络请求
    getCacheSetWeb() {
      getCacheSetWeb().then(res=>{
        if(res.status===200&&res.data) {
          this.webSet = res.data
          console.log(this.webSet)
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  margin-top: 20px;
  .footer-top {
    height: 50px;
    background-color: var(--theme-color);
    .top-center {
      width: 1200px;
      height: 50px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        width: 110px;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        text-align: center;
        color: #fff;
        font-size: 18px;
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
        a {
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
  .footer-bottom {
    height: 160px;
    padding-top: 25px;
    .bottom-center {
      width: 1200px;
      height: 160px;
      margin: 0 auto;
      text-align: center;
      p {
        font-size: 16px;
        color: #313131;
        line-height: 30px;
        a {
          font-size: 16px;
          color: #313131;
        }
      }
    }
  }
}
</style>
