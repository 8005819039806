<template>
	<div class="container header-com">
    <div class="top">
      <div class="top-center">
        <div class="left">
          <div class="time">
            {{ currentDate }}
          </div>
        </div>
        <div class="right">
          <!-- <div class="login-container">
            <div class="login-item">
              <label for="username">用户名</label>
              <input type="text" id="username" name="username" required>
            </div>
            <div class="login-item">
              <label for="password">密码</label>
              <input type="password" id="password" name="password" required>
            </div>
            <div class="login-item last">
              <button type="button" class="login-btn">登录</button>
              <button type="button" class="register-btn">注册</button>
              <div class="forget">忘记密码</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="bottom-center">
        <div class="logo">
          <!-- <img src="../../assets/img/logo.png" alt=""> -->
        </div>
        <div class="search-box">
          <input v-model="serchText" type="text" class="search-input" placeholder="请输入搜索内容" @keydown.enter="goToSearchList">
          <div class="search-btn" @click="goToSearchList">
            <img src="../../assets/img/search-btn.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="nav">
      <div class="nav-center">
        <div class="nav-item" v-for="item in menuList" :key="item.menuId" @mouseenter="enterNavItem(item)" @mouseleave="leaveNavItem(item)">
          <router-link v-if="item.menuName==='首页'" to="home" >{{ item.menuName }}</router-link>
          <template v-else-if="item.linkType===1">
            <a v-if="item.children&&item.children.length>0" href="javascript:;">{{ item.menuName }}</a>
            <router-link v-else :to="item.linkUrl+'?menuId='+item.menuId+'&menuName='+item.menuName+'&layoutMode='+item.layoutMode">{{ item.menuName }}</router-link>
            <div class="item-child-box" v-if="item.children&&item.children.length>0">
              <div class="item-child">
                <div class="child-item" v-for="child in item.children" :key="child.menuId">
                  <template v-if="child.linkType===1">
                    <router-link :to="child.linkUrl+'?menuId='+child.menuId+'&menuName='+item.menuName+'&pid='+child.pid+'&childMenuName='+child.menuName+'&layoutMode='+child.layoutMode">{{ child.menuName }}</router-link>
                  </template>
                  <template v-else-if="child.linkType===2">
                    <a :href="child.linkUrl" target="_blank">{{ child.menuName }}</a>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="item.linkType===2">
            <a :href="item.linkUrl" target="_blank">{{ item.menuName }}</a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllMenuList } from '../../network/menu'

export default {
  name: "Header",
  data () {
    return {
      currentDate: '',
      menuList: [],
      serchText: ''
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getCurrentDate()

    this.getAllMenuList()
  },
  watch: {
    
  },
  methods: {
    enterNavItem(item) {
      if(item&&item.children&&item.children.length>0){
        item.active = true
        console.log(item.active)
      }
    },
    leaveNavItem(item){
      if(item&&item.children&&item.children.length>0){
        item.active = false
        console.log(item.active)
      }
    },
    getCurrentDate(){
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const week = ['日', '一', '二', '三', '四', '五', '六'][now.getDay()];

      this.currentDate = `${year}年${month}月${day}日 星期${week}`;
    },
    goToSearchList() {
      let routeUrl = this.$router.resolve({
        path: '/searchlist?searchText=' + this.serchText,
      })
      window.open(routeUrl.href, '_blank');
    },
    // 网络请求
    getAllMenuList() {
      getAllMenuList().then(res=>{
        if(res.status===200&&res.data) {
          this.menuList = res.data
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  .top {
    width: 100%;
    height: 40px;
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    .top-center {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        .time {
          color: #7d7d7d;
          font-size: 12px;
        }
      }
      .right {
        .login-container {
          display: flex;
          justify-content: start;
          align-items: center;
          .login-item {
            color: #7d7d7d;
            font-size: 12px;
            input {
              width: 140px;
              height: 24px;
              border: 1px solid #e5e5e5;
              border-radius: 4px;
              background-color: #fff;
              margin: 0 10px;
              outline: none;
            }
            &.last {
              display: flex;
              justify-content: start;
              align-items: center;
              button {
                width: 60px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 4px;
                border: none;
                color: #fff;
                &.login-btn {
                  background-color: var(--theme-color);
                  margin-right: 10px;
                }
                &.register-btn {
                  background-color: #7d7d7d;
                  margin-right: 20px;
                }
              }
              .forget {
                line-height: 24px;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    min-width: 1200px;
    width: 100%;
    height: 173px;
    // background-image: url("../../assets/img/header-bg.png");
    // background-position: center 0;
    // background-size: cover;
    // background-repeat: no-repeat;
    .bottom-center {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 603px;
        height: 173px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .search-box {
        width: 341px;
        height: 40px;
        display: flex;
        justify-content: start;
        align-items: center;
        .search-input {
          width: 300px;
          height: 40px;
          border: none;
          outline: none;
          padding-left: 10px;
          background-color: #fff;
          font-size: 16px;
          border-radius: 6px 0 0 6px;
        }
        .search-btn {
          width: 41px;
          height: 40px;
          background-color: #fff;
          border-radius: 0 6px 6px 0;
          border-left: 1px solid #d2d2d2;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .nav {
    width: 100%;
    height: 60px;
    background-color: var(--theme-color);
    .nav-center {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-item {
        font-size: 18px;
        height: 60px;
        line-height: 60px;
        color: #fff;
        position: relative;
        flex: 1;
        text-align: center;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 18px;
          color: #fff;
          // padding: 0 10px;
        }
        &:hover {
          background-color: var(--nav-item-hover);
          .item-child-box {
            .item-child {
              display: block;
            }
          }
        }
        .item-child-box {
          height: auto;
          .item-child {
            z-index: 9;
            display: none;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            background-color: var(--theme-color);
            // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            .child-item {
              text-align: center;
              height: 44px;
              line-height: 44px;
              color: #fff;
              border-top: 1px solid var(--nav-child-border);
              a {
                font-size: 16px;
                color: #fff;
                // padding: 0 10px;
              }
              &:hover {
                background-color: var(--nav-item-hover);
              }
            }
          }
        }
      }
    }
  }
}
</style>
