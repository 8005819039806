import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取网站所有栏目-目录树 /ewi/guest/Menu/GetAllMenuList
export function getAllMenuList(){
  return request({
    url: '/ewi/guest/Menu/GetAllMenuList',
    method: 'get',
    params: {
      systemNumber: systemNumber
    }
  })
}

// 根据栏目编号获取当前编号的二级目录列表 /ewi/guest/Menu/GetWebMenuList
export function getWebMenuList(menuId){
  return request({
    url: '/ewi/guest/Menu/GetWebMenuList',
    method: 'get',
    params: {
      systemNumber: systemNumber,
      menuId
    }
  })
}
